<template>
  <div>
    <div
      class="common-upload-logo__activator"
      @mousemove="handleTooltip"
      @click="showPanel = true"
    >
      <div
        v-if="value"
        class="common-upload-logo__activator-tooltip"
      >
        Click to edit
      </div>

      <span>
        <template v-if="!value">
          <icons-upload
            :width="36"
            :height="36"
            color="currentColor"
            :stroke="0.5"
          />

          Upload logo
        </template>

        <img v-else :src="value" alt="">
      </span>
    </div>

    <v-navigation-drawer
      v-model="showPanel"
      v-lock-scroll="showPanel"
      app
      temporary
      right
      width="750px"
      class="right-side-panel"
    >
      <panel-header :show.sync="showPanel">
        My logos & images
      </panel-header>

      <div class="right-side-panel__content-wrapper">
        <span class="common-upload-logo__files-preferences">
          <b>Upload preferences:</b> 5MB max | JPG, JPEG, PNG, PDF, GIF, SVG, AI, ESP accepted
        </span>

        <div class="common-upload-logo__files">
          <upload-logo @addNewFile="addNewLogo" />

          <div
            v-for="logo in availableLogos"
            :key="logo.id"
            class="common-upload-logo__logo"
            :class="{ 'common-upload-logo__logo--selected': logo.url === selectedImage }"
            @click="selectedImage = logo.url"
          >
            <img :src="logo.url" :alt="logo.caption" draggable="false">

            <icons-check-circle v-if="logo.url === selectedImage" filled filled-color="#6E50FF" />

            <span v-if="logo.caption">{{ logo.caption }}</span>
          </div>
        </div>

        <div class="common-upload-logo__actions">
          <common-button
            :height="44"
            width="200"
            :block="$vuetify.breakpoint.xsOnly"
            @click="saveSelectedLogo()"
          >
            Save changes
          </common-button>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PanelHeader from '@/components/PanelHeader.vue'
import UploadLogo from '@/components/myCampaign/panels/sendEgift/components/UploadLogo.vue'

export default {
  name: 'CommonUploadLogoPanel',
  components: {
    PanelHeader,
    UploadLogo,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    userLogos: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedImage: null,
    availableLogos: [],
    showPanel: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.selectedImage = val || null
      }
    }
  },
  mounted () {
    this.availableLogos = this.userLogos
    this.selectedImage = this.value || null
  },
  methods: {
    addNewLogo (logoData) {
      this.availableLogos = [logoData, ...this.availableLogos];
      this.selectedImage = logoData.url;
      this.$emit('onNewLogo', logoData);
    },
    saveSelectedLogo () {
      this.$emit('input', this.selectedImage);
      this.showPanel = false;
    },
    handleTooltip (event) {
      const tooltip = this.$el.querySelector('.common-upload-logo__activator-tooltip')

      if (tooltip) {
        const tooltipWidth = tooltip.offsetWidth ?? 0
        const pageScrollTop = window.pageYOffset
        const { pageX, clientY } = event

        tooltip.style.left = `${pageX - ((tooltipWidth - 5) / 2)}px`
        tooltip.style.top = `${clientY + pageScrollTop + 28}px`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/common/right-side-panel.scss';

.common-upload-logo {
  &__activator {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 224px;
    min-width: 224px;
    min-height: 90px;
    display: flex;
    cursor: pointer;
    transition: background-color ease-in-out 0.16s,
      border ease-in-out 0.08s;
    background: #FAFAFA;
    border: 1px dashed #95979D;

    & > span {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px;
      font-family: 'Lato-Italic', sans-serif;
      font-weight: 400;
      font-size: 15px;
      margin: 0;
      cursor: inherit;
      line-height: 18px;
      color: #95979D;

      & > img {
        object-fit: contain;
        width: 100%;
        height: 90px;
      }
    }

    &:hover {
      background-color: #EDFCFB;
      border: 1px solid #A7DAD8;

      & .common-upload-logo__activator-tooltip {
        display: block;
      }

      & > span {
        color: #62646A;
      }
    }
  }

  &__activator-tooltip {
    display: none;
    padding: 6px 10px;
    position: absolute;
    z-index: 1;
    background-color: #4A4A4A;
    border-radius: 3px;
    color: #fff;
    font-family: 'Lato-Regular', sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }

  &__files-preferences {
    font-family: 'Lato-Italic', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #95979D;

    & > b {
      font-family: inherit;
      font-weight: 600;
    }

    @media screen and (max-width: 430px) {
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;
      font-size: 14px;
      color: #000000;
    }
  }

  &__files {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 20px;

    @media screen and (max-width: 430px) {
      gap: 30px 8px;
      grid-template-columns: repeat(2, 1fr);

      &::v-deep > .upload-logo__wrapper {
        min-width: unset;
        min-height: unset;
        width: 100%;

        & > label {
          min-width: unset;
          min-height: unset;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  &__logo {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    gap: 6px;

    & > img {
      object-fit: contain;
      width: 196px;
      height: 196px;
      border: 2px solid transparent;
      box-sizing: content-box;
      aspect-ratio: 1 / 1;
    }

    & > span {
      font-family: 'Lato-Regular', sans-serif;
      font-size: 14px;
      line-height: 25px;
      color: #62646A;
    }

    &--selected {
      & > img {
        border-color: #6E50FF;
      }

      & > svg {
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    @media screen and (max-width: 430px) {
      & > img {
        width: 100%;
        height: unset;
        box-sizing: border-box;
      }
    }
  }

  &__actions {
    background: #fff;
    position: sticky;
    width: 100%;
    left: 0;
    bottom: -50px;
    margin-bottom: -50px;
    padding: 10px 0 50px;

    @media screen and (max-width: 430px) {
      & button {
        border-radius: 100px;
      }
    }
  }
}
</style>
