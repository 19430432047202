var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('green-text-with-plus',{staticClass:"pb-6",attrs:{"isOpen":_vm.showAdvanced},on:{"onClick":function($event){_vm.showAdvanced = !_vm.showAdvanced}}},[_vm._v(" "+_vm._s(_vm.showAdvanced ? 'Hide advanced options' : 'Advanced options')+" "),_c('popper',{attrs:{"options":{
        placement: 'top',
        modifiers: { offset: { offset: '0, 5px' } },
      }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"200px","max-width":"500px"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTooltipTextBySlug(!_vm.isPageInIframe ? 'egift_advanced_options' : 'egift_advanced_options_iframe'))}})]),_c('div',{staticClass:"d-flex ml-2",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvanced),expression:"showAdvanced"}]},[(!_vm.isPageInIframe)?_c('v-col',{attrs:{"cols":"12"}},[_c('common-checkbox',{attrs:{"disabled":_vm.disableUpgradeCheckbox},model:{value:(_vm.theAllowToExchange),callback:function ($$v) {_vm.theAllowToExchange=$$v},expression:"theAllowToExchange"}},[_c('span',[_vm._v(" Allow recipient to exchange ")]),_c('popper',{attrs:{"options":{
              placement: 'top',
              modifiers: { offset: { offset: '0, 5px' } },
            }}},[_c('div',{staticClass:"popper pa-3 text-left",staticStyle:{"min-width":"300px","max-width":"480px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_allow_to_exchange'))+" ")]),_c('div',{staticClass:"d-flex ml-2",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])],1)],1):_vm._e(),(!_vm.isPageInIframe)?_c('v-col',{attrs:{"cols":"12"}},[_c('common-checkbox',{attrs:{"disabled":_vm.disableReGiftCheckbox},model:{value:(_vm.theAllowToReEgift),callback:function ($$v) {_vm.theAllowToReEgift=$$v},expression:"theAllowToReEgift"}},[_c('span',[_vm._v(" Allow recipient to Re-gift ")]),_c('popper',{attrs:{"options":{
              placement: 'top',
              modifiers: { offset: { offset: '0, 5px' } },
            }}},[_c('div',{staticClass:"popper pa-3 text-left",staticStyle:{"min-width":"300px","max-width":"450px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_allow_to_re-egift'))+" ")]),_c('div',{staticClass:"d-flex ml-2",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])],1)],1):_vm._e(),(_vm.theLinkType === 'single_link_restricted')?_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"welcome-message"}},[_vm._v("Redeem page welcome message")]),_c('common-input',{staticStyle:{"max-width":"520px"},attrs:{"rules":[(v) => !!v || ''],"maxLength":"450","placeholder":"Enter your details to claim your gift"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"300px","max-width":"500px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_welcome_message'))+" ")]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}],null,false,343778019),model:{value:(_vm.theWelcomeMessage),callback:function ($$v) {_vm.theWelcomeMessage=$$v},expression:"theWelcomeMessage"}}),_c('div',{staticClass:"send-egift-panel-advanced-options__input-counter"},[_vm._v(" Characters left: "+_vm._s(450 - _vm.theWelcomeMessage.length)+" ")])],1):_vm._e(),(!_vm.isPageInIframe && !_vm.isPreProduction && false)?_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"shipping-date"}},[_vm._v(" Shipping arrival date ")]),_c('div',{staticClass:"d-flex align-center",staticStyle:{"margin-left":"4px"}},[_c('single-date-range-picker',{staticStyle:{"position":"relative"},attrs:{"id":"shipping-date","placeholder":"Shipping arrival date","set-min-date-week-later":"","picker-style":"--min-width: 220px;--picker-margin-top: -1px"},model:{value:(_vm.theShippingDate),callback:function ($$v) {_vm.theShippingDate=$$v},expression:"theShippingDate"}}),_c('popper',{attrs:{"options":{
              placement: 'top',
              modifiers: { offset: { offset: '0, 5px' } },
            }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"300px","max-width":"500px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_shipping_dispatch_date'))+" ")]),_c('div',{staticClass:"d-flex ml-2",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])],1)]):_vm._e(),(_vm.panelType === 'egift')?_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"email-subject-line"}},[_vm._v(" Edit email subject line ")]),_c('common-input',{attrs:{"id":"email-subject-line","placeholder":"Enter your subject line","height":"36"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"max-width":"unset"}},[_c('img',{attrs:{"alt":"","width":"590","height":"150","src":_vm.getProductImageBySlug('campaigns_edit_email_subject')}})]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}],null,false,930024966),model:{value:(_vm.theSubjectLine),callback:function ($$v) {_vm.theSubjectLine=$$v},expression:"theSubjectLine"}})],1):_vm._e(),(_vm.userIsCgElite)?_c('v-col',{attrs:{"cols":"12"}},[_c('common-radio',{staticClass:"mb-6",attrs:{"inputValue":"calendly"},scopedSlots:_vm._u([{key:"non-clickable",fn:function(){return [_c('div',{staticClass:"send-egift-panel-advanced-options__user-link-wrapper"},[_c('common-input',{attrs:{"disabled":_vm.userLink !== 'calendly',"placeholder":"Enter your Calendar URL","height":"36"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                      placement: 'top',
                      modifiers: { offset: { offset: '0, 5px' } },
                    }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"max-width":"unset"}},[_c('img',{attrs:{"alt":"","width":"470","height":"280","src":_vm.getProductImageBySlug('campaigns_calendly_meeting_link')}})]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}],null,false,431698278),model:{value:(_vm.theCalendlyUrl),callback:function ($$v) {_vm.theCalendlyUrl=$$v},expression:"theCalendlyUrl"}})],1)]},proxy:true}],null,false,4162800492),model:{value:(_vm.userLink),callback:function ($$v) {_vm.userLink=$$v},expression:"userLink"}},[_vm._v(" Add a Calendar meeting link: ")]),_c('common-radio',{attrs:{"inputValue":"free-form"},scopedSlots:_vm._u([{key:"non-clickable",fn:function(){return [_c('div',{staticClass:"send-egift-panel-advanced-options__user-link-wrapper"},[_c('common-input',{attrs:{"disabled":_vm.userLink !== 'free-form',"placeholder":"https://","height":"36"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                      placement: 'top',
                      modifiers: { offset: { offset: '0, 5px' } },
                    }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"300px","max-width":"500px"}},[_vm._v(" You can add any URL to redirect your recipients ")]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}],null,false,4189551984),model:{value:(_vm.freeFormLinkText),callback:function ($$v) {_vm.freeFormLinkText=$$v},expression:"freeFormLinkText"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('label',{staticClass:"pb-2 lato-regular",attrs:{"for":"button-label"}},[_vm._v(" Set button label (max 20 characters): ")]),_c('common-input',{attrs:{"disabled":_vm.userLink !== 'free-form',"id":"button-label","placeholder":"Go!","height":"36","maxLength":"20"},model:{value:(_vm.freeFormLinkButton),callback:function ($$v) {_vm.freeFormLinkButton=$$v},expression:"freeFormLinkButton"}})],1)],1)]},proxy:true}],null,false,3132051424),model:{value:(_vm.userLink),callback:function ($$v) {_vm.userLink=$$v},expression:"userLink"}},[_vm._v(" Add a free form link: ")])],1):_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"calendly-url"}},[_vm._v(" Add a Calendar meeting link ")]),_c('common-input',{attrs:{"id":"calendly-url","placeholder":"Enter your Calendar URL","height":"36"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"max-width":"unset"}},[_c('img',{attrs:{"alt":"","width":"470","height":"280","src":_vm.getProductImageBySlug('campaigns_calendly_meeting_link')}})]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}]),model:{value:(_vm.theCalendlyUrl),callback:function ($$v) {_vm.theCalendlyUrl=$$v},expression:"theCalendlyUrl"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"video-url"}},[_vm._v(" Add a video greeting ")]),_c('common-input',{attrs:{"id":"video-url","placeholder":"Enter your greeting video URL","clearable":"","height":"36"},on:{"click:clear":_vm.hideGreetingVideo,"blur":_vm.showGreetingVideo},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('popper',{attrs:{"options":{
                placement: 'top',
                modifiers: { offset: { offset: '0, 5px' } },
              }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"max-width":"unset"}},[_c('img',{attrs:{"alt":"","width":"534","height":"360","src":_vm.getProductImageBySlug('campaigns_video_greeting_link')}})]),_c('div',{staticClass:"d-flex ml-5",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])]},proxy:true}]),model:{value:(_vm.theVideoUrl),callback:function ($$v) {_vm.theVideoUrl=$$v},expression:"theVideoUrl"}})],1),(_vm.greetingVideo)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.videoData.showVideo)?_c('video',{attrs:{"src":_vm.videoData.url,"frameborder":"0","title":"Greeting video","allowfullscreen":"false","webkitallowfullscreen":"false","mozallowfullscreen":"false","controls":"","width":"730","height":"460"}}):_vm._e(),(_vm.videoData.showIframe)?_c('iframe',{attrs:{"src":_vm.videoData.url,"frameborder":"0","title":"Greeting video","controls":"","width":"730","height":"460"}}):_vm._e()]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"send-egift-panel-advanced-options__expiration-input"},[_c('label',{attrs:{"for":"egift-expiration-time"}},[_vm._v("eGift will expire after")]),_c('div',[_c('cg-input',{attrs:{"type":"number","width":80,"validation":[_vm.required(), _vm.campaignExpirationDaysValidation],"id":"egift-expiration-time"},model:{value:(_vm.campaignExpirationDays),callback:function ($$v) {_vm.campaignExpirationDays=_vm._n($$v)},expression:"campaignExpirationDays"}}),_vm._v(" days ")],1)])]),(_vm.panelType === 'egift')?_c('v-col',{attrs:{"cols":"12"}},[_c('common-checkbox',{attrs:{"disabled":_vm.disableEmailRemindersCheckbox},model:{value:(_vm.theDisableReminderEmails),callback:function ($$v) {_vm.theDisableReminderEmails=$$v},expression:"theDisableReminderEmails"}},[_c('span',[_vm._v(" Disable reminder emails to recipients ")]),_c('popper',{attrs:{"options":{
              placement: 'top',
              modifiers: { offset: { offset: '0, 5px' } },
            }}},[_c('div',{staticClass:"popper pa-4 text-left",staticStyle:{"min-width":"300px","max-width":"500px"}},[_vm._v(" "+_vm._s(_vm.getTooltipTextBySlug('campaigns_disable_reminder_emails'))+" ")]),_c('div',{staticClass:"d-flex ml-2",attrs:{"slot":"reference"},slot:"reference"},[_c('icons-question',{staticClass:"pointer",attrs:{"width":"24","height":"24"}})],1)])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }