<template>
  <v-dialog
    v-model="show"
    content-class="elevation-0"
    :width="$vuetify.breakpoint.xsOnly ? undefined : 500"
  >
    <div class="automation-campaign-preview-modal__wrapper">
      <div class="automation-campaign-preview-modal__content">
        <div class="automation-campaign-preview-modal__title">{{ modalTitle }}</div>

        <cg-loader class="mx-auto" v-if="loading" />

        <template v-else>
          <span class="automation-campaign-preview-modal__text">
            <template v-if="data.length">
              {{ previewContextText }}
              <br>
              <br>
              <ul>
                <li v-for="recipient in data" :key="recipient.email">
                  {{ [recipient.first_name, recipient.last_name].join(' ') }} - {{ recipient.send_date | dateFilter }}
                </li>
              </ul>
            </template>

            <template v-else>
              We couldn't fetch upcoming automated sends...
            </template>
          </span>

          <div class="automation-campaign-preview-modal__actions">
            <cg-button @click="show = false">Ok</cg-button>
          </div>
        </template>
      </div>

      <v-icon color="white" aria-label="Cancel" class="pointer" @click="show = false">
        mdi-close
      </v-icon>
    </div>
  </v-dialog>
</template>

<script>
import Api from '@/axios/api'
import panelVModel from '@/mixins/panelVModel'

import { CgButton, CgLoader } from '@corporategift/design-system'

import dateFilter from '@corporategift/design-system/filters/dateFilter';

export default {
  name: 'TheAutomationCampaignPreviewModal',
  mixins: [panelVModel],
  filters: { dateFilter },
  components: {
    CgButton,
    CgLoader,
  },
  props: {
    previewData: {
      type: Object,
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: false,
    data: [],
  }),
  computed: {
    modalTitle () {
      const { previewData } = this

      if (!previewData) {
        const { type } = previewData
        const automationType = type === 'anniversary' ? 'Work anniversary' : 'Birthday'

        return `Next ${automationType} eGifts to Be Sent`
      }

      return 'Preview upcoming automated sends'
    },
    previewContextText () {
      const { loading, data, previewData } = this

      if (loading) { return null }
      if (!previewData) { return null }

      const numberOfRecipients = data?.length ?? 0

      if (numberOfRecipients === 5) {
        return 'This is a preview of the next 5 recipients in your automation queue, according to your settings:'
      }

      return `Based on your settings, we found ${numberOfRecipients} recipients in the automation queue:`
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.loadRecipientsData()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  methods: {
    loadRecipientsData () {
      this.loading = true

      Api.post('/campaigns/automation/upcoming-sends', {
        ...this.previewData,
        payment: undefined,
        is_for_approval: undefined,
      })
        .then(({ data }) => (this.data = data))
        .catch(() => (this.$cgToast.error('An error occurred, please contact our support')))
        .finally(() => (this.loading = false))
    }
  },
}
</script>

<style lang="scss" scoped>
.automation-campaign-preview-modal {
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media screen and (max-width: 430px) {
      & > .v-icon {
        display: none;
      }
    }
  }

  &__content {
    gap: 20px;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;

    @media screen and (max-width: 430px) {
      padding: 16px;
    }
  }

  &__title {
    font-family: 'Lato-Bold', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  &__text {
    font-family: 'Lato-Regular', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000;

    & > ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &__actions {
    gap: 12px;
    display: flex;
    flex-direction: row;

    & > button,
    & > a {
      border-radius: 100px;
    }
  }
}
</style>
